import dynamic from 'next/dynamic';

import { minWidth } from '@hultafors/shared/style';

import { ImageBlockFragment } from '@hultafors/eripiowear/types';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';

import { ImageBlockStyled } from './image-block.styled';

const DatoImage = dynamic(
  () =>
    import('@hultafors/shared/components').then((module) => module.DatoImage),
  { ssr: false },
);

export interface ImageBlockProps extends ImageBlockFragment {
  priority?: boolean;
}

export const ImageBlock: React.FC<ImageBlockProps> = ({
  image,
  imageLoad,
  priority = false,
}) => {
  const sizes = [
    minWidth.desktopLarge`1200px`,
    minWidth.desktop`85vw`,
    '100vw',
  ].join(', ');
  return (
    <ImageBlockStyled>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 5 },
            { breakpoint: 'mobileMax', columns: 12 },
            { breakpoint: 'desktop', columns: 10, start: 2 },
          ]}
        >
          <DatoImage image={imageLoad} sizes={sizes} priority={priority} />
        </GridChild>
      </Grid>
    </ImageBlockStyled>
  );
};
